<template>
    <transition name="fade">
        <div v-show="isOpen" class="fixed left-0 top-[4rem] xl:top-[9rem] w-full">
            <div
                class="bg-white xl:bg-transparent search-dropdown z-30 container lg:px-0 overflow-y-scroll overflow-x-hidden lg:overflow-visible"
            >
                <div
                    class="h-screen lg:h-auto lg:overflow-y-scroll nicescroll-medium xl:-ml-6 xl:w-[calc(100%+3rem)] relative"
                >
                    <div class="grid grid-cols-12 xl:rounded-md xl:overflow-hidden">
                        <div
                            class="col-span-12 lg:col-span-4 bg-blue-900 pt-14 pb-10 md:pb-12 lg:pb-24 search-form-wrapper lg:pr-12 h-max lg:h-full relative lg:pl-8"
                        >
                            <button
                                class="close-btn close-btn--white flex lg:hidden z-1"
                                @click="closeDropdown"
                            ></button>

                            <span
                                class="font-Inter-Bold text-2xl mb-5 text-white w-full text-center lg:text-left lg:w-auto"
                                >Keresés</span
                            >
                            <div
                                class="search-input-wrapper flex items-center relative w-full z-10 lg:mb-8"
                                :class="!thin && 'search-icon'"
                            >
                                <div ref="inputContainer" class="w-full">
                                    <InputText
                                        id="searchInput"
                                        ref="input"
                                        v-model="searchText"
                                        :class="thin ? 'border-none bg-transparent pl-6' : 'pl-12'"
                                        placeholder="Írd ide a keresett kifejezést!"
                                        @input="search"
                                        @focus="$store.commit('toggleSearchDropdown', true)"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    !results.products.length && !results.categories.length && !results.articles.length
                                "
                                class="hidden lg:block"
                            >
                                <span class="font-Inter-Semibold text-base mb-2 text-white items-center block"
                                    >Használat</span
                                >
                                <span class="font-Inter-Regular text-xs leading-5 text-white block"
                                    >A kereső használatához kezdj el gépelni legalább három karaktert. A rendszer
                                    azonnal megjeleníti a releváns cikkeket, kategóriákat és termékeket a beírt szavak
                                    alapján.
                                </span>
                            </div>

                            <div v-if="$device.isDesktop" class="hidden md:flex flex-col mt-4">
                                <div v-show="results.products.length" :class="results.categories.length && 'mb-6'">
                                    <span
                                        class="block w-full text-left font-Inter-Bold text-base pb-3 border-b border-dashed border-white border-opacity-20 mb-4 xl:pl-0 text-white"
                                        >Termékek</span
                                    >
                                    <div class="flex flex-col gap-y-3">
                                        <div
                                            v-for="product in results.products.slice(0, 3)"
                                            :key="product?.id"
                                            @click="closeDropdown"
                                        >
                                            <nuxt-link
                                                class="font-Inter-Regular text-white hover:underline"
                                                :to="
                                                    localePath({
                                                        name: 'products-category-product',
                                                        params: {
                                                            category: product?.category_slug,
                                                            product: product?.slug,
                                                        },
                                                    })
                                                "
                                                >{{ product.name }}
                                            </nuxt-link>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="results.categories.length" :class="results.articles.length && 'mb-6'">
                                    <span
                                        class="block w-full text-left font-Inter-Bold text-base pb-3 border-b border-dashed border-white border-opacity-20 mb-4 xl:pl-0 text-white"
                                        >Kategóriák</span
                                    >
                                    <div class="flex flex-col gap-y-3">
                                        <div class="h-full" @click="closeDropdown">
                                            <nuxt-link
                                                v-for="category in results.categories"
                                                :key="`category-${category?.id}`"
                                                :to="
                                                    localePath({
                                                        name: 'products-category',
                                                        params: { category: category.translations[0].slug },
                                                    })
                                                "
                                                class="font-Inter-Regular text-white hover:underline"
                                                >{{ category.translations[0]?.name }}
                                            </nuxt-link>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="results.articles.length">
                                    <span
                                        class="block w-full text-left font-Inter-Bold text-base pb-3 border-b border-dashed border-white border-opacity-20 mb-4 xl:pl-0 text-white"
                                        >Magazin</span
                                    >
                                    <div class="flex flex-col gap-y-3">
                                        <div class="h-full" @click="closeDropdown">
                                            <nuxt-link
                                                v-for="(article, i) in results.articles.slice(0, 10)"
                                                :key="`article-${i}`"
                                                :to="
                                                    localePath({
                                                        name: 'magazine-article',
                                                        params: {
                                                            article: article.slug,
                                                        },
                                                    })
                                                "
                                                class="font-Inter-Regular text-white hover:underline"
                                                >{{ article.title.slice(0, 32) }}...
                                            </nuxt-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-span-12 lg:col-span-8 bg-white pt-2 lg:pt-14 pb-12 lg:pb-24 lg:pl-4 lg:pr-4 relative max-h-screen lg:w-auto lg:overflow-y-scroll nicescroll-medium"
                            :class="
                                !results.products.length && !results.categories.length && !results.articles.length
                                    ? 'flex items-center'
                                    : ''
                            "
                        >
                            <div
                                class="full rounded-sm bg-white lg:px-4 font-Inter-Bold w-full transition-all lg:overflow-y-scroll nicescroll-medium"
                                :class="{
                                    'lg:pb-20':
                                        Object.values(results).filter((property) => property.length > 0).length > 1,
                                    'lg:pb-0':
                                        Object.values(results).filter((property) => property.length > 0).length === 1,
                                    'pb-0':
                                        Object.values(results).filter((property) => property.length > 0).length === 0,
                                }"
                            >
                                <div
                                    v-show="results.products.length"
                                    :class="(results.articles.length || results.categories.length) && 'mb-8'"
                                >
                                    <span
                                        class="block w-full text-left font-Inter-Bold text-xl pb-3 border-b border-dashed border-black border-opacity-20 mb-6 xl:pl-0 mt-4 lg:mt-0"
                                        >Termékek</span
                                    >
                                    <div class="grid grid-cols-4 lg:grid-cols-3 gap-x-4 gap-y-4 lg:gap-y-0">
                                        <div
                                            v-for="product in results.products.slice(0, 3)"
                                            :key="`product-${product.id}`"
                                            class="col-span-2 lg:col-span-1"
                                        >
                                            <div @click="closeDropdown">
                                                <ProductCard
                                                    :title="product.name"
                                                    :product="product"
                                                    :has-btn-text="false"
                                                    :slug="
                                                        localePath({
                                                            name: 'products-category-product',
                                                            params: {
                                                                category: product?.category_slug,
                                                                product: product?.slug,
                                                            },
                                                        })
                                                    "
                                                    :brutto-price="product.price"
                                                    :is-favorite="product.isFavorite"
                                                    :price="product.price"
                                                    :discounted-price="product.discounted_price"
                                                    :image="
                                                        product.category_name === 'WonderWood'
                                                            ? product.image
                                                            : product.preview
                                                    "
                                                    class="min-h-[25.4rem]"
                                                    md
                                                />
                                            </div>
                                        </div>
                                        <div
                                            v-if="results.products.length > 3"
                                            class="col-span-5 lg:col-span-3 flex justify-center -mt-2 lg:mb-0 lg:mt-4"
                                        >
                                            <div @click="closeDropdown">
                                                <more-button :to="localePath('products')" label="További termékek" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="results.articles.length" :class="results.categories.length && 'mb-8'">
                                    <span
                                        class="block w-full text-left font-Inter-Bold text-xl pb-3 border-b border-dashed border-black border-opacity-20 mb-6 xl:pl-0 mt-4 lg:mt-0"
                                        >Magazin</span
                                    >
                                    <div class="grid grid-cols-3 gap-x-4">
                                        <div
                                            v-for="article in results.articles.slice(0, 3)"
                                            :key="`product-${article.id}`"
                                            class="col-span-3 lg:col-span-1"
                                        >
                                            <div class="h-full" @click="closeDropdown">
                                                <ArticleCard
                                                    :title="article.title"
                                                    :img-src="article.image"
                                                    :description="`${article.lead.slice(0, 60)}...`"
                                                    :url="
                                                        localePath({
                                                            name: 'magazine-article',
                                                            params: {
                                                                article: article.slug,
                                                            },
                                                        })
                                                    "
                                                    :category="article.category"
                                                    minutes="5"
                                                    :publish-date="article.public_date"
                                                    md
                                                />
                                            </div>
                                        </div>
                                        <div
                                            v-if="results.articles.length > 3"
                                            class="col-span-3 flex justify-center mt-8"
                                            @click="closeDropdown"
                                        >
                                            <more-button :to="localePath('magazine')" label="További cikkek" />
                                        </div>
                                    </div>
                                </div>
                                <div v-show="results.categories.length">
                                    <span
                                        class="block w-full text-left font-Inter-Bold text-xl pb-3 border-b border-dashed border-black border-opacity-20 mb-6 xl:pl-0 mt-4 lg:mt-0"
                                        >Kategóriák</span
                                    >
                                    <div class="flex flex-wrap gap-5 mt-7">
                                        <div @click="closeDropdown">
                                            <nuxt-link
                                                v-for="category in results.categories"
                                                :key="`category-${category?.id}`"
                                                :to="
                                                    localePath({
                                                        name: 'products-category',
                                                        params: { category: category.translations[0].slug },
                                                    })
                                                "
                                                class="py-2 px-6 rounded-full font-Inter-Bold text-blue-100 bg-blue-100 bg-opacity-5 border border-blue-100 border-opacity-60 hover:border-opacity-100"
                                            >
                                                {{ category.translations[0]?.name }}
                                            </nuxt-link>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-show="
                                        !results.categories.length &&
                                        !results.products.length &&
                                        !results.articles.length
                                    "
                                    class="w-full text-center flex flex-col justify-center items-center mt-[25%] lg:mt-0"
                                >
                                    <i
                                        class="block text-3xl lg:text-[2.25rem] mb-2 lg:mb-3"
                                        :class="handleFeedbackIcon"
                                    ></i>
                                    <div v-if="noResults && searchText.length > 3" class="flex flex-col items-center">
                                        <span class="text-lg block mb-2"
                                            >Sajnos nem találtunk a keresésnek megfelelő elemet</span
                                        >
                                        <span class="text-sm font-Inter-Regular block"
                                            >Próbálj meg más kifejezéssel keresni címre vagy kategóriára.</span
                                        >
                                    </div>
                                    <div v-else class="flex flex-col items-center">
                                        <span class="text-lg block mb-2"
                                            >A keresés indításához kérlek kezdj el gépelni legalább 3 karaktert!</span
                                        >
                                        <span class="text-sm font-Inter-Regular block"
                                            >Cikkek címében, kategóriákban és termékek nevében.</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="close-btn hidden lg:block" @click="closeDropdown"></button>
                </div>
            </div>
            <div
                class="fixed -top-6 left-0 w-full h-full lg:min-h-[calc(100vh+1.5rem)] bg-black opacity-0 z-[-1] overlay min-h-screen"
                :class="{ 'pointer-events-none': arePointerEventsDisabledOnBgOverlay }"
                @click="closeDropdown"
            ></div>
        </div>
    </transition>
</template>

<script>
import { debounce } from 'vue-debounce';
import ProductCard from '../Cards/ProductCard/ProductCard.vue';
import ArticleCard from '@/components/Cards/ArticleCard/ArticleCard.vue';
import MoreButton from '@/components/UI/Buttons/MoreButton.vue';

export default {
    name: 'SearchDropdown',
    components: {
        ProductCard,
        ArticleCard,
        MoreButton,
    },
    props: {
        thin: {
            type: Boolean,
            required: false,
            default: false,
        },
        isOpen: {
            type: Boolean,
            required: true,
        },
        close: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            searchText: '',
            arePointerEventsDisabledOnBgOverlay: true,
            loading: false,
            noResults: false,
            results: {
                products: [],
                categories: [],
                articles: [],
            },
        };
    },
    computed: {
        handleFeedbackIcon() {
            if (this.loading) {
                return 'loader block transition';
            } else if ((!this.loading && this.searchText.length < 3) || !this.noResults || this.searchText === 0) {
                return 'ri-information-line';
            } else if (this.noResults && !this.loading && this.searchText.length > 3) {
                return 'sad-emoji';
            }
            return 'ri-information-line';
        },
    },
    watch: {
        isOpen(from, to) {
            if (this.isOpen) {
                setTimeout(() => {
                    this.arePointerEventsDisabledOnBgOverlay = false;
                    document.getElementById('searchInput').focus();
                    document.body.style.overflow = 'hidden';
                }, 250);
            } else {
                document.body.style.overflow = 'visible';
            }
            if (to === false) {
                this.results.categories = [];
                this.results.products = [];
                this.results.articles = [];
                this.searchText = '';
            }
        },
        $route(to, from) {
            this.closeDropdown();
        },
    },
    mounted() {
        window.addEventListener('keydown', this.handleEscapeKey);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleEscapeKey);
    },
    methods: {
        normalizeSearchText(text) {
            return text
                .toLocaleLowerCase('tr')
                .normalize('NFD')
                .replace(/[\u0300-\u036F]/g, '');
        },
        handleEscapeKey(event) {
            if (event.key === 'Escape') {
                this.closeDropdown();
            }
        },
        closeDropdown() {
            document.body.style.overflow = 'visible';
            this.arePointerEventsDisabledOnBgOverlay = true;
            this.searchText = '';
            this.close();
        },
        search: debounce(function () {
            if (this.searchText.length >= 3) {
                this.loading = true;
                this.$axios
                    .get(`/search?keyword=${this.searchText}`)
                    .then((response) => {
                        if (response.data.result === 'success') {
                            if (response.data.data.products.length) {
                                this.results.products = response.data.data.products;
                            } else {
                                this.results.products = [];
                            }
                            if (response.data.data.categories.length) {
                                this.results.categories = response.data.data.categories;
                            } else {
                                this.results.categories = [];
                            }
                            if (response.data.data.articles.length) {
                                this.results.articles = response.data.data.articles;
                            } else {
                                this.results.articles = [];
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.noResults =
                            this.searchText.length > 3 &&
                            !this.loading &&
                            !this.results.categories.length &&
                            !this.results.products.length &&
                            !this.results.articles.length;
                    });
            } else {
                this.loading = false;
                this.results.categories = [];
                this.results.products = [];
                this.results.articles = [];
            }
        }, 250),
    },
};
</script>

<style scoped>
.search-input-wrapper {
    @apply flex items-center;
}

.search-icon {
    &::before {
        content: '';
        @apply w-6 h-6 bg-center bg-no-repeat bg-contain pointer-events-none absolute left-4;
        background-image: url('~/assets/images/icons/magnifier-icon.svg');
    }
}

.close-btn {
    @apply w-5 h-5 top-12 xl:top-6 absolute right-0 lg:right-8 top-8 bg-center bg-no-repeat bg-contain transition hover:scale-110;
    background-image: url('~/assets/images/icons/close-icon-black.svg');

    &--white {
        background-image: url('~/assets/images/icons/close-icon.svg');
    }
}

.dropdown {
    box-shadow: 0 4px 200px rgba(0, 0, 0, 0.05);
}

.search-dropdown {
    box-shadow: 0 4px 20px rgba(29, 29, 29, 0.03);
}

.search-form-wrapper {
    @media (max-width: 1279px) {
        &::before {
            content: '';
            transform: translateX(1px);
            @apply absolute block h-full pointer-events-none w-screen ml-[-100vw] top-0 bg-blue-900;
        }
    }

    @media (max-width: 1024px) {
        &::after {
            content: '';
            @apply absolute block h-full pointer-events-none w-screen right-0 top-0 bg-blue-900 -right-full;
        }
    }
}

.h-max {
    @media (max-width: 992px) {
        height: max-content;
    }
}

.overlay {
    @keyframes fadeInOverlay {
        from {
            @apply opacity-0;
        }
        to {
            @apply opacity-60;
        }
    }
    animation: fadeInOverlay 0.25s forwards 0.15s;
}

.loader {
    width: 23px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 3px solid #001426;
    animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}
@keyframes l20-1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0, 50% 0, 50% 0, 50% 0%, 50% 0%);
    }
    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0, 100% 0, 100% 0, 100% 0%, 100% 0%);
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0, 100% 0, 100% 100%, 50% 100%, 0% 100%);
    }
    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }
    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
}
@keyframes l20-2 {
    0% {
        transform: scaleY(1) rotate(0deg);
    }
    49.99% {
        transform: scaleY(1) rotate(135deg);
    }
    50% {
        transform: scaleY(-1) rotate(0deg);
    }
    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
}
</style>
